import React from "react";

const ContactOne = () => {
  return (
    <section className="contact_form_area">
      <div className="contact_form_width">
        <h1 className="contact_map_title text-center">
          Naš rad će zagarantovano ispuniti vaša očekivanja.
        </h1>
        <div id="map">
          <iframe
            title="template google map"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2833.7376354084317!2d20.475632817443845!3d44.74536919999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a711305099007%3A0x76bc045d9dd8d9e1!2sBulevar%20JNA%2022%2C%20Beograd!5e0!3m2!1sen!2srs!4v1670164792064!5m2!1sen!2srs" 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d362281.8538956186!2d20.142414908230165!3d44.81490282615904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7aa3d7b53fbd%3A0x1db8645cf2177ee4!2sBelgrade!5e0!3m2!1sen!2srs!4v1679597273087!5m2!1sen!2srs"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="contact_form_width contact-right">
        <div className="hero-title-with-shape">
          <h4 className="heading_with_border">Dobijte besplatnu ponudu</h4>
          <h1>Popunite formu kako bi Vas neko iz servisa kontaktirao</h1>
        </div>
        <form action="#" method="post">
          <input
            className="half_width input_m_right"
            type="text"
            name="fname"
            placeholder="Vaše ime"
          />
          <input
            className="half_width"
            type="EMAIL"
            name="email"
            placeholder="Email adresa"
          />
          <input type="tel" name="tell" placeholder="Broj telefona" />
          <span className="select_icon">
            <select name="select" id="select">
              <option value="option0">Odaberite uslugu</option>
              <option value="option1">Servisiranje klima uređaja</option>
              <option value="option2">Instalacija klima uređaja</option>
              <option value="option3">Prodaja klima uređaja</option>
              <option value="option4">Tehnička podrška</option>
            </select>
          </span>
          <button className="btn-yellow" value="SUBMIT NOW" disabled>
            POŠALJITE
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactOne;
