/** @format */

import React from "react";
import Img from "./img";

const BlogCard = ({ data }) => {
  const { image, date, title, text } = data;
  return (
    <div className="blog_share_box">
      <div className="bl_share_img">
        <Img src={image} alt={title} className="img-fluid" />
        <span className="blog_date">{date}</span>
      </div>
      <div className="blog_share_details">
        <span className="comment_author">
          {/* <Link href={url}>{author}</Link> -{" "} */}
          {/* <Link href={url}>{commentCount} Sviđanja</Link> */}
        </span>
        <h1>
          {/* <Link href={url}>{title}</Link> */}
          {title}
        </h1>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default BlogCard;
